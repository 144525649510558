export class OrderStatuses {
  static ORDER_STATUSES = {
    'new': {
      shortLabel: 'Waiting for confirmation',
      longLabel: 'Waiting for confirmation'
    },
    'processing': {
      shortLabel: 'Awaiting kitchen acceptance',
      longLabel: 'Awaiting kitchen acceptance'
    },
    'accepted': {
      shortLabel: 'Order accepted & being prepared',
      longLabel: 'Order accepted & being prepared'
    },
    'declined': {
      shortLabel: 'Order declined',
      longLabel: 'Order declined'
    },
    'en-route': {
      shortLabel: 'Delivering now. PIN = last 4 digits of your phone number',
      longLabel: 'Delivering now. PIN = last 4 digits of your phone number'
    },
    'completed': {
      shortLabel: 'Successfully delivered',
      longLabel: 'Successfully delivered'
    },
    'unsuccessful': {
      shortLabel: 'Unsuccessful',
      longLabel: 'Unsuccessful'
    },
    'refund-due': {
      shortLabel: 'Refund Due',
      longLabel: 'Refund Due'
    },
    'collected': {
      shortLabel: 'Collected',
      longLabel: 'Collected'
    },
    'cancelled': {
      shortLabel: 'Cancelled',
      longLabel: 'Cancelled'
    },
    'refunded': {
      shortLabel: 'Refunded',
      longLabel: 'Refunded'
    },

  };
}
