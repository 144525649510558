import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {CartService} from '../../../services/cart.service';

import {Events} from '../../../services/events.service';

@Component({
    selector: 'app-product-modal',
    templateUrl: './product-modal.component.html',
    styleUrls: ['./product-modal.component.scss'],
})
export class ProductModalComponent implements OnInit {

    // quantity = 1;
    // total = 0;
    // subTotal = 0;
    // cartItem = {};
    // checkboxData = {};
    // limit = 0;
    // currentlyHave = 0;
    @Input() product: any;
    @Input() category: any;
    @Input() merchant: any;
    addOns: any;
    currentItemTotal = 0;
    single_choice_add_ons = false;


    constructor(public modalController: ModalController, navParams: NavParams, private events: Events, private alert: AlertController, public cartService: CartService) {
        this.product = navParams.get('product');
        this.category = navParams.get('category');
        this.merchant = navParams.get('merchant');
        this.cartService.load(this.product, this.category, this.merchant);
    }

    ngOnInit() {
        console.log(this.product);

        this.product.variations.forEach((product) => {

            product.options.forEach((option) => {

              if(option.is_default) {
                product.default = option;

                this.cartService.optionChangedDefault(product.name, option);

                this.currentItemTotal = this.cartService.currentTotal;

                this.addOns = option.add_ons;

                console.log("ADD ONS", this.addOns);

                if(option.single_choice_add_ons) {
                  this.single_choice_add_ons = true;
                } else {
                  this.single_choice_add_ons = false;
                }

              }

              if(option.no_stock_merchant) {
                option.no_stock_merchant.forEach((merchant) => {
                  if(merchant.merchant == this.merchant.id) {
                    option.no_stock = true;
                  }
                });
              }
            });

        });
    }

    closeModal() {
        this.modalController.dismiss();
    }

    plus() {
        this.cartService.plus();

        this.currentItemTotal = this.cartService.currentTotal;
    }

    minus() {
        this.cartService.minus();

        this.currentItemTotal = this.cartService.currentTotal;
    }

    addToCart() {
        if (this.cartService.validate()) {
            if (this.cartService.addToCart()){
                this.modalController.dismiss(
                    {
                        product_name: '',
                        product_description: '',
                    }
                );
            }
        }
    }

    optionChanged(e, variation) {
        this.cartService.optionChanged(e, variation);

        this.currentItemTotal = this.cartService.currentTotal;

        if(e.target.value.add_ons) {
          this.addOns = e.target.value.add_ons;

          if(e.target.value.single_choice_add_ons) {
            this.single_choice_add_ons = true;
          } else {
            this.single_choice_add_ons = false;
          }
        }

    }

    optionChangedAddon(e, variation) {
        this.cartService.optionChanged(e, variation);

        this.currentItemTotal = this.cartService.currentTotal;
    }

    checkChanged(e, variation, option) {
        this.cartService.checkChanged(e, variation, option);

        this.currentItemTotal = this.cartService.currentTotal;
    }

    checkChangedAddon(e, variation, option) {
        this.cartService.checkChanged(e, variation, option);

        this.currentItemTotal = this.cartService.currentTotal;
    }

    calculateCartItem() {
        this.cartService.calculateCartItem();
    }


}
